.SentUpdateModal > p,
.SentUpdateModal > ul,
.SentUpdateModal > ol {
  display: block;
  margin: 1rem 0;
}

.SentUpdateModal ul {
  margin-left: 1rem;
  list-style: disc;
}

.SentUpdateModal ol {
  margin-left: 1rem;
  list-style: number;
}

.SentUpdateModal li {
  margin-bottom: 0.75rem;
}

.SentUpdateModal a {
  @apply text-oxide;
}

.SentUpdateModal > :last-child {
  margin-bottom: 0;
}

.SentUpdateModal a {
  @apply text-oxide-3 underline;
}
