#UpdateComposer .remirror-editor {
  font-family: WhitneySsm, sans-serif;
  outline: none;
  min-height: 150px;
}

#UpdateComposer .remirror-editor p {
  margin: 1rem 0;
}

#UpdateComposer .remirror-editor ul,
#UpdateComposer .remirror-editor ol {
  margin: 1rem 0 1rem 1rem;
}

#UpdateComposer .remirror-editor ul {
  list-style: disc;
}

#UpdateComposer .remirror-editor ol {
  list-style: number;
}

#UpdateComposer .remirror-editor li {
  margin-bottom: 0.75rem;
}

#UpdateComposer .remirror-editor :first-child {
  margin-top: 0;
}

#UpdateComposer .remirror-editor :last-child {
  margin-bottom: 0;
}

#UpdateComposer .remirror-editor a {
  @apply text-oxide-3 underline;
}
